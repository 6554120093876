@font-face {
font-family: '__inter_1361c6';
src: url(/_next/static/media/cc27cf3ff100ea21-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__inter_Fallback_1361c6';src: local("Arial");ascent-override: 89.79%;descent-override: 22.36%;line-gap-override: 0.00%;size-adjust: 107.89%
}.__className_1361c6 {font-family: '__inter_1361c6', '__inter_Fallback_1361c6'
}.__variable_1361c6 {--font-inter: '__inter_1361c6', '__inter_Fallback_1361c6'
}

@font-face {
font-family: '__exo2_da19bd';
src: url(/_next/static/media/86a19cbd70da903c-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__exo2_Fallback_da19bd';src: local("Arial");ascent-override: 96.89%;descent-override: 19.49%;line-gap-override: 0.00%;size-adjust: 103.11%
}.__className_da19bd {font-family: '__exo2_da19bd', '__exo2_Fallback_da19bd'
}.__variable_da19bd {--font-exo2: '__exo2_da19bd', '__exo2_Fallback_da19bd'
}

